.founder {
  width: 255px;
  min-height: 235px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* background-color: var(--bg-light); */
}

.founder > span.founder__image {
  width: 180px;
  height: 180px;
  margin: 5px auto;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s;
  text-transform: capitalize;
  font-size: 75px;
  /* background-color: rgb(47, 13, 89); */
  color: linear-gradient(var(--bg-light), var(--bg-main));
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: 'Diplomata SC', cursive;
  justify-content: center;
}
.founder > span.founder__image:hover {
  border: solid 12px var(--bg-light);
  font-size: 70px;
}

.founder > h3 {
  margin-bottom: 0;
  font-size: large;
  text-transform: uppercase;
  color: darkblue;
  text-align: center;
}

.founder > h4 {
  margin-top: 0;
  text-decoration: wavy;
  text-transform: capitalize;
}

.founder .socials {
  gap: 10px;
}
.founder .socials .founder__icon {
  font-size: 1.7em;
}
.founder .socials .founder__icon:hover {
  fill: var(--bg-light);
  transform: translateY(-3px);
}

/* Founder Detail styling */

.founder__detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.founder__detail > section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.founder__detail section.photo {
  padding: 10px;
  align-items: center;
}
.founder__detail section.photo img {
  object-fit: contain;
  width: 95%;
  height: 95%;
  max-width: 250px;
  max-height: 300px;
}

.founder__detail section.info {
  gap: 5px;
}

.founder__detail section.info h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: larger;
  font-weight: 800;
}
.founder__detail section.info h4 {
  text-transform: capitalize;
  margin: 0;
  font-size: small;
  color: purple;
}
.founder__detail section.info p {
  text-align: justify;
}

@media screen and (min-width: 900px) {
  .founder__detail {
    flex-direction: row;
  }
  .founder {
    margin: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .founder__detail section.photo img {
    max-width: 300px;
    max-height: 400px;
  }
}
