.roadmap > div.flex {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.roadmap .phase {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  max-width: 400px;
  border-radius: 24px;
  background-color: var(--bg-light);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.roadmap .phase:hover {
  border: solid 2px var(--bg-white);
  transform: translateY(-3px);
  background-color: var(--bg-main);
}

.phase .phase__no {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20pt;
  font-weight: 800;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  gap: 5px;
  border: solid 4px var(--bg-white);
}
.phase .phase__no > i {
  font-size: 14pt;
}

.phase > ul {
  list-style-type: square;
  margin: 0;
  padding: 0 15px;
}
.phase li {
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: small;
}

@media screen and (min-width: 900px) {
  .roadmap > div.flex {
    flex-direction: row;
  }
}
