.founders {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
}

.founders > p {
  max-width: 600px;
  font-size: large;
  text-align: center;
  margin: 10px auto;
}

.founders__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.founders__container-2 {
  min-height: max-content;
  width: 80vw;
  margin: 10px auto !important;
}

.founders__container-2 .fonder_wrapper {
  margin: 10px auto 1rem auto;
  /* display: flex; */
  /* justify-content: center; */
}
@media screen and (min-width: 900px) {
  .founders__container {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
