.token {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  padding: 16px;
  border: solid 1px var(--bg-white);
  border-radius: 16px;
  transition: 0.2s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.token:hover {
  border: solid 2px var(--bg-main);
  background-color: var(--bg-white);
  color: var(--bg-main);
  transform: translateY(3px);
}

.token:hover .token__icon {
  color: var(--bg-main);
}

.token > span {
  font-size: large;
}

.token > span.key {
  font-weight: 700;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.token .token__icon {
  font-size: 2em;
  color: var(--bg-white);
}

@media screen and (min-width: 900px) {
  .token {
    min-width: 350px;
  }
  .token span {
    flex: 1;
  }
}
