.osm .about__text .addresses {
  text-align: left;
  font-size: large;
  margin-bottom: 1rem;
}

.addresses > strong {
  width: 150px;
  display: inline-block;
}
.addresses > .MuiLink-root {
  text-overflow: ellipsis;
}
