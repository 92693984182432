.home {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  /* padding-top: 20px; */
}

.home > section {
  padding: 10px;
}

.home > section.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: bolder;
}

.home__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__image > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 700px;
  max-height: 60vh;
}
.btn.MuiButton-root {
  padding: 6px 20px;
  border: 2px solid darkblue;
  margin-top: 15px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: var(--bg-light);
}
.btn.MuiButton-root:hover {
  transition: 0.2s;
  background-color: var(--bg-white);
  color: var(--bg-main);
  transform: translateY(-3px);
}
.home .btn.MuiButton-root.btn-large {
  padding: 15px 30px;
  font: size 18px;
  font-weight: 600;
}

@media screen and (min-width: 900px) {
  .home {
    flex-direction: row;
    /* padding-top: 34px; */
  }
  .home > section {
    padding: 30px;
  }
  section.left.home__text {
    flex: 1;
  }

  section.right.home__image {
    flex: 0.6;
    border-radius: 26px;
  }
}
