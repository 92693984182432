.container > div.flex {
  flex-direction: column;
}

.about__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about__image > img {
  object-fit: contain;
  width: 80%;
  height: 80%;
  max-width: 380px;
  max-height: 380px;
  margin-bottom: 10px;
}

.about__text {
  color: inherit;
}

.about__text p {
  text-align: justify;
  margin-top: 0;
  font-size: 12pt;
}

.about__text h4 {
  margin: 0;
}

/* .about .about__image > img:hover {
  transform: rotateZ(30deg);
} */
@media screen and (min-width: 900px) {
  .container > div.flex {
    flex-direction: row;
    flex: 1;
  }

  .about__image {
    flex: 0.4;
  }
  .about__text {
    flex: 0.6;
  }
  .about__text p {
    font-size: 16pt;
  }
}
