.comingsoon {
  /* width: 300px; */
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
  font-style: italic;
  color: green;
  font-family: 'Diplomata SC', cursive;
  font-weight: 700;
}

@media screen and (min-width: 900px) {
  .comingsoon {
    width: 380px;
    height: 500px;
    font-size: 18pt;
  }
}
