:root {
  --bg-main: #030412;
  --bg-light: #171150;
  --bg-white: rgba(255, 255, 255, 0.961);
}
::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.container {
  /* padding: 20px; */
  padding: 75px 20px 20px 20px;
}

@media screen and (min-width: 900px) {
  .container {
    padding-top: 95px 34px 34px 34px;
  }
  .container > div.flex {
    min-height: 100%;
  }
}

@media screen and (min-width: 1100px) {
  .container {
    min-height: 100vh;
  }
}

